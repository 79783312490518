import React, { useState } from 'react'
import { Button, Form, Input, Popover } from 'antd'

import { content } from '../../constans'
import style from './Footer.module.scss'

export default function Footer() {
    const [form] = Form.useForm()

    const [checked, setChecked] = useState(true)
    const [open, setOpen] = useState(false)

    const onSubmit = (e) => {
        console.log(e)
        form.resetFields()
    }
    const onChecked = () => {
        setChecked(item => !item)
        setOpen(item => !item)
    }
    const onGetData = () => {
        // fetch("https://b24-6ty1i4.bitrix24.by/rest/1/rxv62v9icm812hhy/crm.contact.list.json")
        // .then(res => res.json())
        // .then(res => console.log(res.result))
        fetch("https://portal.bitrix24.com/oauth/authorize/?client_id=local.6716168edb3cd1.49652990")
        .then(res => res.json())
        .then(res => console.log(res))
      }
      const onPostData = () => {
        fetch("https://b24-6ty1i4.bitrix24.by/rest/1/cup33b10umsvv7hw/crm.contact.add.json", {
          method: "post",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields:
            { 
              "NAME": "Дмитрий", 
              "SECOND_NAME": "", 
              "LAST_NAME": "Иванов", 
              //"OPENED": "Y", 
              //"ASSIGNED_BY_ID": 15, 
              //"TYPE_ID": "CLIENT",
              //"SOURCE_ID": "SELF",
              // "PHONE": [ { "VALUE": "555888", "VALUE_TYPE": "WORK" } ] 	
            },
              //params: { "REGISTER_SONET_EVENT": "Y" }	
          })
        })
        .then(res => res.json())
        .then(res => console.log(res))
      }

    return (
        <div className={style.footer}>
            <span className={style.mainTitle}>КOНТАКТЫ</span>
            <div className={style.wrapContact}>
                <div className={style.wrapAdress}>
                    <div className={style.wrapTopBlock}>
                        <div className={style.block}>
                            <div className={style.arrowImage}/>
                            <span className={style.title}>АДРЕС ОФИСА ПРОДАЖ</span>
                            <span className={style.text}>г. Минск, ул. К. Маркса, 13а, каб.105</span>
                        </div>
                        <div className={style.block}>
                            <div className={style.clockImage}/>
                            <span className={style.title}>РЕЖИМ РАБОТЫ</span>
                            <span className={style.text}>пн-чт: 8.30 - 17.45, <br/>пт: 8.30 - 16.30, <br/>перерыв 13.00 - 14.00</span>
                        </div>
                    </div>
                    <div className={style.wrapBottomBlock}>
                        <div className={style.block}>
                            <div className={style.phoneImage}/>
                            <span className={style.title}>ТЕЛЕФОН</span>
                            <span className={style.text}>+375 (29) 195-18-21</span>
                        </div>
                    </div>
                </div>
                <div className={style.wrapForm}>
                    <span className={style.title}>Поделитесь мнением о нашей работе или задайте нам любой интересующий вас вопрос в поле комментарий</span>
                    <Form
                        //onFinish={(e) => onSubmit(e)}
                        form={form}
                        initialValues={{remember: false}}
                        className={style.form}
                    >
                        <Form.Item 
                            name='name'
                            rules={[{ required: true, message: 'Введите ваше имя!' }]}
                        >
                            <Input
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)' }}
                                className={style.input}
                                placeholder='Имя'
                            />
                        </Form.Item>
                        <Form.Item 
                            name='email'
                        >
                            <Input
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)' }}
                                className={style.input}
                                placeholder='Email'
                            />
                        </Form.Item>
                        <Form.Item 
                            name='phone'
                            rules={[{ required: true, message: 'Введите ваш телефон!' }]}
                        >
                            <Input
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)' }}
                                className={style.input}
                                placeholder='Телефон'
                            />
                        </Form.Item>
                        <Form.Item 
                            name='comment'
                        >
                            <Input.TextArea
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)' }}
                                className={style.textArea}
                                placeholder='Комментарий'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                //htmlType='submit'
                                className={style.btn}
                                style={{ border: 'none'}}
                                onClick={onGetData}
                                >Отправить
                            </Button>
                        </Form.Item>
                        <input 
                            type="checkbox"
                            checked={checked}
                            onClick={onChecked}
                            style={{width: 15, height: 15}}
                        />
                        <Popover
                            placement="topRight"
                            content={content.footer.agreement}
                            trigger="click"
                            open={open}
                            overlayStyle={{width: '300px', height: '200px', overflow: 'hidden'}}
                        />
                        <span className={style.text}>Нажимая кнопку «Отправить», я принимаю условия пользовательского соглашения и даю согласие на обработку моих персональных данных на условиях и для целей, определенных в Согласии на обработку персональных данных</span>
                    </Form>
                </div>
            </div>

            <div className={style.wrapColumns}>
                <div className={style.column} style={{width: "50%"}}>
                    <span className={style.title} >ГПО "МИНСКСТРОЙ"</span>
                    <span className={style.text} style={{textDecoration: "none"}}>{content.footer.aboutMSK}</span>
                    <div className={style.wrapFooterContact}>
                        <div className={style.contactRow}>
                            <div className={style.homeImage}/>
                            <div className={style.text}>Адрес офиса продаж: <span className={style.boldText}>г.Минск, ул. К.Маркса, 13а, каб. 105</span></div>
                        </div>
                        <div className={style.contactRow}>
                            <div className={style.clock1Image}/>
                            <div className={style.text}>Режим работы: <span className={style.boldText}><br/>пн-чт: 8.30 - 17.45, <br/>пт: 8.30 - 16.30 <br/>перерыв 13.00 - 14.00</span></div>
                        </div>
                        <div className={style.contactRow}>
                            <div className={style.collImage}/>
                            <div className={style.text}>Телефон: <span className={style.boldText}>+375 (29) 195-18-2</span>1</div>
                        </div>
                        <div className={style.contactRow}>
                            <div className={style.addressImage}/>
                            <div className={style.text}>Юридический и почтовый адрес: <span className={style.boldText}>220030, г.Минск, ул.К.Маркса, 13А</span></div>
                        </div>
                    </div>
                </div>
                <div className={style.column}>
                    <span className={style.title}>О ЗАСТРОЙЩИКЕ</span>
                    <a href="https://minskstroy.by/ru" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text}>Официальный сайт застройщика</span></a>
                    <a href="https://minskstroy.by/ru/rekvizity" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text}>Реквизиты</span></a>
                    <a href="https://minskstroy.by/ru/telefony-sluzhb-i-podrazdeleniy" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text}>Телефоны служб и подразделений</span></a>
                    <a href="https://minskstroy.by/ru/shema-proezda" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text}>Схема проезда</span></a>
                </div>
                <div className={style.column}>
                    <span className={style.title}>О КОМПЛЕКСЕ</span>
                    <span className={style.text}>Выбрать квартиру</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Об Уютном</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Инфраструктура</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Расположение</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Характеристики домов</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Паркинг</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Новости</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Условия оплаты</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Контакты</span>
                </div>
                    
            </div>
        </div>
    )
}
