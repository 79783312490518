import React, { useEffect, useState} from 'react'
import ChessHeader from '../ChessHeader/ChessHeader'

import style from './Chess.module.scss'

export default function Chess() {
  return (
    <div className={style.mainChess}>
      <ChessHeader/>
      <div className={style.wrapChess}>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>20</div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'gray'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'brown'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
        </div>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>19</div>
          <div className={style.chess} style={{backgroundColor: 'brown'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'gray'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
        </div>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>18</div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: '#403A3A'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'brown'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
        </div>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>17</div>
          <div className={style.chess} style={{backgroundColor: 'gray'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'brown'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
        </div>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>16</div>
          <div className={style.chess} style={{backgroundColor: 'brown'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: '#403A3A'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: 'green'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
          <div className={style.chess} style={{backgroundColor: '#403A3A'}}>
            <div className={style.wrapText}><span className={style.text}>4 комнаты</span><span className={style.text}>№ 160</span></div>
            <span className={style.text}>404 200 BYN</span>
            <div className={style.wrapText}><span className={style.text}>80 m2</span><span className={style.text}>5000 byn/m2</span></div>
          </div>
        </div>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>15</div>
          <div className={style.chess} style={{backgroundColor: '#403A3A'}}></div>
          <div className={style.chess} style={{backgroundColor: 'green'}}></div>
          <div className={style.chess} style={{backgroundColor: 'brown'}}></div>
          <div className={style.chess} style={{backgroundColor: 'green'}}></div>
        </div>
        <div className={style.chessBlock}>
          <div className={style.numFlat}>14</div>
          <div className={style.chess} style={{backgroundColor: 'green'}}></div>
          <div className={style.chess} style={{backgroundColor: '#403A3A'}}></div>
          <div className={style.chess} style={{backgroundColor: 'green'}}></div>
          <div className={style.chess} style={{backgroundColor: 'green'}}></div>
        </div>
      </div>
    </div>
    
  )
}
