export const content = {
    footer: {
        aboutMSK: "Государственное производственное объединение «Минскстрой» — это крупнейшая организация строительной отрасли Беларуси, представляющая собой сбалансированную систему организаций различного профиля, что позволяет реализовывать самые сложные проекты в области строительства: от разработки и обоснования инвестирования до ввода объекта в эксплуатацию.",
        agreement: 
        <span>
            Согласие на обработку персональных данных <br/><br/>
            Настоящим Я выражаю свое свободное, однозначное и информированное согласие на обработку предоставленных мною персональных данных , зарегистрированному по адресу: (далее по тексту – Оператор), в соответствии с указанными ниже сведениями:
            
            <br/><br/>1. Оператор может совершать следующие действия с моими персональными данными: сбор, систематизация, хранение, изменение, использование, обезличивание, блокирование, распространение, предоставление, удаление персональных данных.
            
            <br/><br/>2. Согласие выдано мною на обработку следующих персональных данных:
            <br/>- Имя;
            <br/>- E-mail;
            <br/>- Телефон;
            <br/>- Комментарий.

            <br/><br/>3. Оператор может обрабатывать мои персональные данные в следующих целях:
            <br/>- для предоставления мне услуг/работ;
            <br/>- направления в мой адрес уведомлений, касающихся предоставляемых услуг/работ;
            <br/>- подготовки и направления ответов на мои запросы.

            <br/><br/>а также, с моего согласия, которое я настоящим выражаю, для направления в мой адрес информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора.

            <br/><br/>4. Способы обработки персональных данных: с использованием средств автоматизации, без их использования, смешанным способом.

            <br/><br/>5. Оператор может вносить мои персональные данные в системы Битрикс24 и 1С-Битрикс, тем самым поручая обработку моих персональных данных ИУП «1С Битрикс», УНП 192042385, юридический адрес: 220035, г. Минск, пр-т Победителей, д. 59, оф. 202, как уполномоченному лицу.


            <br/><br/>6. Срок действия согласия – до момента его отзыва.

            <br/><br/>7. Предоставляя свое согласие на обработку персональных данных, я подтверждаю, что мне были разъяснены мои права как субъекта персональных данных и механизм их реализации:
            <br/>а) право на получение сведений об обработке персональных данных Оператором;
            <br/>б) право на получении информации о предоставлении информации уполномоченным лицам;
            <br/>в) право на внесение изменений в свои персональные данные;
            <br/>г) право требовать прекращения обработки персональных данных и их удаления;
            <br/>д) право на отзыв согласия на обработку.

            <br/><br/>Для реализации одного или нескольких из вышеуказанных прав, необходимо направить Оператору заявление, в котором должны быть указаны: фамилия, имя, отчество (если имеется); адрес места жительства (места пребывания); дата рождения; изложение сути требований; личная подпись или электронная цифровая подпись.

            <br/><br/>Для реализации прав из п. а – г необходимо направить Оператору данное заявление:
            <br/>- в письменной форме по адресу: .
            <br/>- в виде электронного документа на электронный адрес: realtyprotech.com@gmail.com.

            <br/><br/>Для реализации права на отзыв (п. д) необходимо сообщить Оператору о своем намерении отозвать согласие на электронный адрес realtyprotech.com@gmail.com или направить Оператору заявление:
            <br/>- в письменной форме на адрес: .
            <br/>- в виде электронного документа на электронный адрес: realtyprotech.com@gmail.com.

            <br/><br/>8. Я осведомлен (-а) о том, что отказ от дачи согласия на обработку моих персональных данных или прекращение обработки персональных данных Оператором может сделать невозможным получение мной информации о мероприятиях/товарах/услугах/работах Оператора.
            Согласие на обработку персональных данных
        </span>
    }
}