import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Footer from '../Footer/Footer'
import Header from '../Header/Header'

import style from './Main.module.scss'

export default function Main() {
//  useEffect(() => {
// //   document.addEventListener('DOMContentLoaded', function() {
// //     document.getElementById('form_to_crm').addEventListener('submit', function(el) {
// //         el.preventDefault();
// //         let formData = new FormData(this);
// //         let sName = formData.get("NAME");
// //         let sLastName = formData.get("LAST_NAME");
// //         let sPhone = formData.get("PHONE");
// //         let sEmail = formData.get("EMAIL");

// //         let arPhone = sPhone ? [{ 'VALUE': sPhone, 'VALUE_TYPE': 'WORK' }] : [];
// //         let arEmail = sEmail ? [{ 'VALUE': sEmail, 'VALUE_TYPE': 'HOME' }] : [];

// //         BX24.callMethod(
// //             'crm.lead.add',
// //             {
// //                 'fields': {
// //                     'TITLE': 'From the site: ' + [sName, sLastName].join(' '),
// //                     'NAME': sName,
// //                     'LAST_NAME': sLastName,
// //                     'PHONE': arPhone,
// //                     'EMAIL': arEmail
// //                 }
// //             },
// //             function(result) {
// //                 if (result.error()) {
// //                     console.error(result.error());
// //                     alert('Lead not added: ' + result.error_description());
// //                 } else {
// //                     alert('Lead add');
// //                 }
// //             }
// //         );
// //     });
// // });
// }, [])

//void BX24.callMethod(
  // String method,
  // Object params[,
  //     Function callback
  // ]
//);

  const [ showPayment, setShowPayment ] = useState(false)
  const [ showInstallment, setShowInstallment ] = useState(false)
  const [ showCredit, setShowCredit ] = useState(false)

  // useEffect(() => {
  //   // https://portal.bitrix24.com/oauth/authorize/?client_id=local.6710d8145ad805.78212999
  //   fetch("https://portal.bitrix24.com/oauth/authorize/?client_id=local.6710d8145ad805.78212999")
  //   .then(res => res.json())
  //   .then(res => console.log(res))
  // }, [])
  

  const onGetData = () => {
    // fetch("https://b24-6ty1i4.bitrix24.by/rest/1/rxv62v9icm812hhy/crm.contact.list.json")
    // .then(res => res.json())
    // .then(res => console.log(res.result))
    fetch("https://portal.bitrix24.com/oauth/authorize/?client_id=local.6716168edb3cd1.49652990")
    .then(res => res.json())
    .then(res => console.log(res))
  }
  const onPostData = () => {
    fetch("https://b24-6ty1i4.bitrix24.by/rest/1/cup33b10umsvv7hw/crm.contact.add.json", {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields:
        { 
          "NAME": "Дмитрий", 
          "SECOND_NAME": "", 
          "LAST_NAME": "Иванов", 
          //"OPENED": "Y", 
          //"ASSIGNED_BY_ID": 15, 
          //"TYPE_ID": "CLIENT",
          //"SOURCE_ID": "SELF",
          // "PHONE": [ { "VALUE": "555888", "VALUE_TYPE": "WORK" } ] 	
        },
          //params: { "REGISTER_SONET_EVENT": "Y" }	
      })
    })
    .then(res => res.json())
    .then(res => console.log(res))
  }

  return (
    <div className={style.mainWrapper}>
      <Header/>
      <div className={style.mainImage}/>
      <Link className={style.wrapChooseFlat} to="/chess">
        <div className={style.flatImage}/>
        <span>Выбрать</span>
        <span>квартиру</span>
      </Link>
      <div className={style.wrapAdvantages}>
        <span className={style.title}>ОТ &nbsp;ИДЕИ &nbsp;К &nbsp;ВОПЛОЩЕНИЮ</span>
        <div className={style.advantages}>
          <div className={style.block} style={{marginTop: 80}}>
            <div className={style.flat1Image}/>
            <span className={style.titleAdvant}>Качество внутри и снаружи</span>
            <span className={style.textAdvant}>
              Конструктивная схема здания – монолитный-железобетонный каркас.<br/><br/>
              При строительстве используются высококачественные материалы и современные технологии строительства.<br/><br/>
              Все стены возводятся из керамзитобетонных блоков, фасад выполнен с применением декоративной плитки и штукатурки с эффектом кирпичной кладки и утеплен толстым слоем минваты, в квартирах установлены 2- камерные окна из алюминиевого профиля с детскими замками безопастности.
            </span>
          </div>
          <div className={style.block}>
            <div className={style.homeImage}/>
            <span className={style.titleAdvant}>Авторская архитектура</span>
            <span className={style.textAdvant}>
              Каждый проект — это отражение индивидуальности.<br/><br/>  
              Использование новых технологий, материалов и незаурядных решений, делает здания не просто пространством для жизни или работы, но и произведением искусства, 
                которые способны вдохновлять и вызывать эмоции.
            </span>
          </div>
          <div className={style.block} style={{marginTop: 80}}>
            <div className={style.lotusImage}/>
            <span className={style.titleAdvant}>Собственная философия</span>
            <span className={style.textAdvant}>
              Гармоничное сосуществование человека и городской среды.<br/>
              Удобное расположение в сердце города.<br/><br/>
              Устойчивое сообщество жильцов.<br/><br/>
              Доступ к необходимым услугам, таким как магазины, школы, медицинские учреждения.<br/><br/>
              Философия жилого комплекса в центре города подчеркивает важность создания комфортного, безопасного и вдохновляющего пространства для жизни, чтобы каждый житель чувствовал себя частью целого.
            </span>
          </div>
        </div>
      </div>
      <div className={style.wrapMap}>
        <span className={style.title}>ИНФРАСТРУКТУРА</span>
        <span className={style.text}>
          Жить в историческом центре города — значит быть частью деловой и    культурной жизни столицы.<br/><br/>
          Концентрация престижных школ, высших учебных заведений и мест дополнительного и спортивного образования к вашим услугам.<br/><br/>
          Метро и транспортные развязки позволяют быстро доехать до любого района города. Не нужно тратить драгоценное время на дорогу.  
          Высокая динамика жизни, масса развлечений, кафе, баров и ресторанов заскучать вам не дадут!<br/>
          В пешей доступности от вашего дома вы найдете все необходимое комфортной для жизни.
        </span>
        <span className={style.title} style={{marginTop: 20}}>РАСПОЛОЖЕНИЕ</span>
        <span className={style.text} style={{marginBottom: 30}}>Жилой комплекс расположен в границах улиц Захарова – Андреевская. 
          <br/>Ближайшие станции метро «Пролетарская» и «Площадь победы».
        </span>
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A77db8c2e3ce6154a0a4c75b02b075c3604338e9028742eccb601fd1408ad2676&amp;source=constructor" width="100%" height="550" frameborder="0">
        </iframe>
      </div>
      <div className={style.wrapPaymenTerms}>
        <span className={style.title}>УСЛОВИЯ &nbsp;ОПЛАТЫ</span>
        <div className={style.wrapModule}>
          <div className={showPayment ? style.showModule : style.module}
            onClick={() => setShowPayment(item => !item)}
          > <span className={style.titleModule}>100% оплата</span>
            <span className={style.textModule} style={{display: showPayment ? "flex" : "none"}}>
              Полная оплата квартиры и машино-места производится в течение 7 (семи) банковских дней.
            </span>
          </div>
          <div className={showInstallment ? style.showModule : style.module}
            onClick={() => setShowInstallment(item => !item)}
          >
            <span className={style.titleModule}>Рассрочка</span>
            <span className={style.textModule} style={{display: showInstallment ? "flex" : "none"}}>
              Первоначальный взнос при условии оплаты согласно графику платежей 
              по жилому помещению (квартире) составляет 40%;
              по машино-месту - 40% от стоимости объекта долевого строительства.
              <br/>Оплата должна быть произведена дольщиком в течение 7 (семи) 
              банковских дней со дня регистрации.
            </span>
          </div>
          <div className={showCredit ? style.showModule : style.module}
            onClick={() => setShowCredit(item => !item)}
          >
            <span className={style.titleModule}>Кредит</span>
            <span className={style.textModule} style={{display: showCredit ? "flex" : "none"}}>
              Беларусбанк <br/> Белинвестбанк
            </span>
          </div>
        </div>
      </div>
      <Footer/>


      {/* <div className={style.get}
        onClick={onGetData}
      >Получить</div>
      <div className={style.post}
        onClick={onPostData}
      >Отправить</div> */}
    </div>
  )
}
