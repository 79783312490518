import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'

import style from './ChessHeader.module.scss'

export default function ChessHeader() {
    const navigate = useNavigate()

    const [inter, setInter] = useState(false)
    const [plane, setPlane] = useState(false)
    const [chess, setChess] = useState(true)

    const onChoosePage = (e) => {
        if (e === "chess") {
            setChess(true) 
            setInter(false)
            setPlane(false)
        }
        if (e === "inter") {
            setChess(false) 
            setInter(true)
            setPlane(false)
        }
        if (e === "plane") {
            setChess(false) 
            setInter(false)
            setPlane(true)
        }
    }
    return (
        <>
            <div className={style.wrapChessHeader}>
                <Link className={style.wrapHome} to="/">
                    <div className={style.homeImage}/>
                    <span>Главная</span>
                </Link>
                <div className={style.chessBtn}>
                    <div className={style.wrapBtn}
                        style={{backgroundColor: inter ? "#1472FE" : ''}}
                        onClick={() => onChoosePage("inter")}
                    >
                        <span style={{color: inter ? 'white' : 'gray'}}>Интерактив</span>
                    </div>
                    <div className={style.wrapBtn}
                        style={{backgroundColor: plane ? "#1472FE" : ''}}
                        onClick={() => onChoosePage("plane")}
                    >
                        <span style={{color: plane ? 'white' : 'gray'}}>Планировки</span>
                    </div>
                    <div className={style.wrapBtn} 
                        style={{backgroundColor: chess ? "#1472FE" : ''}}
                        onClick={() => onChoosePage("chess")}
                        >
                        <span style={{color: chess ? 'white' : 'gray'}}>Шахматка</span>
                    </div>
                </div>
                <div className={style.wrapPhone}>
                    <div className={style.phoneImage}/>
                    <a href="tel:+375291951821" aria-label="phone" className={style.phone} style={{textDecoration: 'none'}}>+375(29)195-18-21</a>
                </div>
            </div>
            <div className={style.wrapStatus}>
              <div className={style.status} style={{backgroundColor: 'green'}}><span>Свободно</span></div>  
              <div className={style.status} style={{backgroundColor: 'brown'}}><span>Бронь</span></div>
              <div className={style.status} style={{backgroundColor: '#403A3A'}}><span>Продано</span></div>
              <div className={style.status} style={{backgroundColor: 'darkgrey'}}><span>Нет в продаже</span></div>
            </div>
        </>
    )
}
