import React from 'react'

import style from './Header.module.scss'

export default function Header() {
  return (
    <div className={style.header}>
        <div className={style.wrapLinks}>
            <span className={style.links}>ЖИЛАЯ НЕДВИЖИМОСТЬ</span>
            <span className={style.links}>О КОМПЛЕКСЕ</span>
            <span className={style.links}>ПАРКИНГ</span>
            <span className={style.links}>УСЛОВИЯ ОПЛАТЫ</span>
            <span className={style.links}>КОНТАКТЫ</span>
        </div>
    </div>
  )
}
