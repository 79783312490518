const initialState = {
    menu: [],
    postMenu: [],
}

const restMenuReducer = (state = initialState, action) => {
    switch (action.type){ 
        case 'POST_MENU':
          
            return {
                ...state,
                postMenu: action.payload
            }
        default: 
        return state;  
    }
}

export default restMenuReducer

