import React from 'react'
import {Route,  Routes} from 'react-router-dom'

import Main from '../Main/Main'
import Chess from '../Chess/Chess'
import Layouts from '../Layouts/Layouts'

import style from './App.module.scss'

export default function App() {
  
  return (
    <div className={style.app}>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/chess" element={<Chess/>}/>
        <Route path="/layouts" element={<Layouts/>}/>
      </Routes>
    </div>
  )
}